import { useState, useEffect } from 'react';
import Header from '../../components/Header';

export interface ChatterProps {
  averageConvictionPercentage: number;
  percentageChange: number;
  positiveConvictionPercentage: number;
  negativeConvictionPercentage: number;
  mixedConvictionPercentage: number;
  cryptoProjects: string[];
  projectName: string;
  tokenSymbol: string;
  chainName: string;
  logo: string;
  total_mentions: number;
  total_weighted_view_score: number;
  channelCount: number;
}

export interface PendingProps {
  videoId: string;
  channelName: string;
  videoLink: string;
  title: string;
  views: number;
  cryptoProjects: string[];
}

export interface ServerStatsProps {
  channelName: string;
  cronJobStatus: {
    videoScanner: {
      isRunning: boolean;
      lastRunTime: string;
      totalRuns: number;
      nextScheduledRun: string;
      scannerRunning: false;
    },
    githubPush: {
      isRunning: boolean;
      lastRunTime: string;
      totalRuns: number;
      nextScheduledRun: string;
      pushRunning: boolean;
    },
    tokenPopulate: {
      isRunning: boolean;
      isExecuting: boolean;
      schedulerExists: boolean;
      lastRunTime: string;
      totalRuns: number;
      nextScheduledRun: string;
      currentInterval: number;
      isInitialized: boolean;
    }
  },
  videoStats: {
    latestPendingVideo: {
      videoId: string;
      channelName: string;
      title: string;
      timestamp: number;
    };
    latestScannedVideo: {
      videoId: string;
      channelName: string;
      title: string;
      timestamp: number;
    };
    latestVideo: {
      videoId: string;
      channelName: string;
      title: string;
      timestamp: number;
      status: string;
    };
    totalVideosAllTime: number;
    totalVideosInTimespan: number;
    uniqueChannelsInTimespan: number;
  };
}

function Chatter({ title = 'Chatter' }: { title?: string }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPending, setLoadingPending] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [sentimentData, setSentimentData] = useState<ChatterProps[] | null>(null);
  const [serverStatusData, setServerStatusData] = useState<ServerStatsProps | null>(null);
  const [topViews, setTopViews] = useState<PendingProps[] | null>(null);
  const [count, setCount] = useState(0);
  const [slideClass, setSlideClass] = useState('fade-in-slow');

  const getTimestamps = () => {
    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    return {
      currentDate: formatDate(new Date()),
      oneMonthAgoDate: formatDate(oneMonthAgo),
    };
  };

  useEffect(() => {
    const fetchTopTokens = async () => {
      setLoading(true);
      setError(false);

      const { currentDate, oneMonthAgoDate } = getTimestamps();

      try {
        const response = await fetch(
          process.env.REACT_APP_USE_REMOTE_API === 'true'
            ? `//${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/top-tokens?interval=daily&start_date=${oneMonthAgoDate}&end_date=${currentDate}&category=meme`
            : `//${process.env.REACT_APP_AWS_API}/api/top-tokens?interval=daily&start_date=${oneMonthAgoDate}&end_date=${currentDate}&category=meme`,
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data: ChatterProps[] = await response.json();
        setSentimentData(data);
      } catch (error) {
        console.error('Error fetching top tokens:', error);
        setError(true);
      } finally {
        setLoading(false);
      }

      try {
        const responsePending = await fetch(
          process.env.REACT_APP_USE_REMOTE_API === 'true'
            ? `//${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/pending-videos-crypto-projects`
            : `//${process.env.REACT_APP_AWS_API}/api/pending-videos-crypto-projects`,
        );

        if (!responsePending.ok) {
          throw new Error(`HTTP error! status: ${responsePending.status}`);
        }
        const dataPending: PendingProps[] = await responsePending.json();
        setTopViews(dataPending);
        console.log(dataPending);
      } catch (error) {
        console.error('Error fetching top tokens:', error);
      } finally {
        setLoadingPending(false);
      }

      try {
        const responseServerStatus = await fetch(
          process.env.REACT_APP_USE_REMOTE_API === 'true'
            ? `//${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/scanner-status?startDate=2024-10-01&endDate=2024-12-31`
            : `//${process.env.REACT_APP_AWS_API}/api/scanner-status?startDate=2024-10-01&endDate=2024-12-31`,
        );

        if (!responseServerStatus.ok) {
          throw new Error(`HTTP error! status: ${responseServerStatus.status}`);
        }
        const dataServerStatus: ServerStatsProps = await responseServerStatus.json(); // Change to single object
        setServerStatusData(dataServerStatus);

        console.log("server status", dataServerStatus);
      } catch (error) {
        console.error('Error fetching SERVER STATUS:', error);
      }
    };

    fetchTopTokens();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => {
        if (sentimentData && prevCount < sentimentData.length - 1) {
          return prevCount + 1;
        } else {
          return 0; // Reset to the first item
        }
      });
    }, 6000);

    return () => clearInterval(interval);
  }, [sentimentData]);

  useEffect(() => {
    // Reset the class to trigger re-animation
    setSlideClass('');
    const timeout = setTimeout(() => {
      setSlideClass('fade-in-slow');
    }, 50); // Small delay to allow the class to be removed and re-added

    return () => clearTimeout(timeout);
  }, [count]);

  // Helper function to replace '/thumb/' with '/standard/'
  const replaceThumbWithStandard = (url: string): string => {
    return url.replace('/thumb/', '/standard/');
  };

  return (
    <>
      <div className="meme-container">
        <div className='header-container'>
          <Header title={"Activity"} />
          {(loading || error) &&
            <div className="loading"><span>{process.env.REACT_APP_USE_REMOTE_API === 'true' && 'CHECK VPN'}</span> <i className="fa-sharp-duotone fa-solid fa-spinner-third"></i></div>
          }
        </div>

        <div>
          {serverStatusData && serverStatusData.videoStats && (
            <div>
              {/* <h2>SCANNER RUNNING</h2> */}
              <div className="chatter-graph">
                {/* <i className="fa-solid fa-database"></i> */}
                
                {serverStatusData.cronJobStatus.videoScanner.isRunning ? (
                  <>
                    <div
                      className="chatter-heartbeat"
                    >
                      <i className="fa-sharp-duotone fa-solid fa-grip-lines-vertical"></i>
                    </div>
                    {serverStatusData.cronJobStatus.videoScanner.isRunning && <div className='scanner-status-active'>SCAN IS RUNNING</div>}
                  </>
                ) : (
                  <>
                    <i className="fa-sharp-duotone fa-solid fa-grip-lines-vertical"></i>
                    <i className="fetching-alert spin"></i>
                    <i className="fa-sharp-duotone fa-solid fa-grip-lines-vertical"></i>
                  </>
                )}
                {/* <i className="fa-solid fa-computer-classic"></i> */}
              </div>

              

              <h2>Latest Pending Video:</h2>
              <p>{serverStatusData.videoStats.latestPendingVideo.title} by {serverStatusData.videoStats.latestPendingVideo.channelName}</p>
              <h2>Latest Scanned Video:</h2>
              <p>{serverStatusData.videoStats.latestScannedVideo.title} by {serverStatusData.videoStats.latestScannedVideo.channelName}</p>
              <h2>Latest Video:</h2>
              <p>{serverStatusData.videoStats.latestVideo.title} by {serverStatusData.videoStats.latestVideo.channelName}</p>
            </div>
          )}
        </div>

        <div className="chatter-ticker-wrap">
          <label>TOP MEMES</label>
          <div className="chatter-ticker">
            {sentimentData && sentimentData.map((item, index) => (
              <div key={`original-${index}`} className="chatter-ticker-item">
                <img id="logo" src={replaceThumbWithStandard(item.logo)} alt="Logo" />
                <h1>{item.projectName}<span>{item.tokenSymbol}</span></h1>
              </div>
            ))}
            {sentimentData && sentimentData.map((item, index) => (
              <div key={`duplicate-${index}`} className="chatter-ticker-item">
                <img id="logo" src={replaceThumbWithStandard(item.logo)} alt="Logo" />
                <h1>{item.projectName}<span>{item.tokenSymbol}</span></h1>
              </div>
            ))}
          </div>
        </div>

        <div className="chatter-ticker-reverse-wrap">
          <div className="chatter-ticker-reverse">
            <div key={`original`} className="chatter-ticker-reverse-item">
              <h1>Top mentions in the last 3 days <b>MOTH</b> <b>CORGIAI</b><b>BONK</b></h1>
            </div>
            <div key={`duplicate`} className="chatter-ticker-reverse-item">
              <h1>Top mentions in the last 3 days <b>MOTH</b> <b>CORGIAI</b><b>BONK</b></h1>
            </div>
          </div>
        </div>

        {/* AVATAR when ChannelID is available `https://yt3.ggpht.com/channel/${topViews[count].channelId`; */}

        {topViews && topViews.map((item, index) => index < 5 && (
              <div key={`${index}`} className="chatter-ticker-item">
                  <h1>{item.views.toLocaleString()}</h1>
                  <h2>{item.channelName}</h2>
              </div>
            ))}

        {/* {topViews && topViews.length > 0 && (
          <>
              <div className={`chatter-data ${slideClass}`}>
                <img height="72" src={`https://img.youtube.com/vi/${topViews[count].videoId}/mqdefault.jpg`} alt="Logo" />
                <h1>{topViews[count].views.toLocaleString()}</h1>
                <h2>{topViews[count].channelName}</h2>
                <a>{topViews[count].videoLink}</a>

                {topViews[count].cryptoProjects && topViews[count].cryptoProjects.length > 0 && (
                  <div className="crypto-projects">
                    {topViews[count].cryptoProjects.map((project, index) => (
                      <div key={`${index}-${project}`}>{project}</div>
                    ))}
                  </div>
                )}
                <br />
              </div>
          </>
        )} */}

        {/* {sentimentData && sentimentData.length > 0 && (
          <>
            <div className="general-data">
              <div className='title'>
                <img id='logo' src={replaceThumbWithStandard(sentimentData[count].logo)} alt="Logo" />
                <h1 id='effect'>{sentimentData[count].projectName} <span>{sentimentData[count].tokenSymbol}</span></h1>
              </div>
            </div>

            <div className="general-data-tri">
              <div className="rollup">
                <label>CHAIN NAME</label>
                <h2>
                  {sentimentData[count].chainName}
                </h2>
              </div>
              <div className="rollup">
                <label>MENTIONS</label>
                <h2>
                  {sentimentData[count].total_mentions}
                </h2>
              </div>
              <div className="rollup">
                <label>CHANNEL COUNT</label>
                <h2>
                  {sentimentData[count].channelCount}
                </h2>
              </div>
            </div>
          </>
        )} */}
      </div>
    </>
  );
}

export default Chatter;