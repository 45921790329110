import React from 'react';
import Header from '../Header';
import Rollup from '../Rollup';
import FullscreenCurtains from '../FullscreenCurtains';
import useFullscreenCurtains from '../FullscreenCurtains/useFullscreenCurtains';
import { ListProps } from '../../types/interfaces';

interface FeatureWithInteractiveListProps {
  premium?: boolean;
  title: string;
  listItems: ListProps[];
  selectedItem: ListProps | null;
  featuredStatName: string;
  featureBackground: boolean;
  FeaturedComponent?: React.ComponentType<{
    listItem: ListProps | null;
    featuredStatName: string;
    featureBackground: boolean;
    secondaryFeaturedStatName?: string;
    y1Measuring?: string;
  }>;
  setSelectedItem: (item: ListProps | null) => void;
  y1Measuring?: string;
  renderListItem: (item: ListProps, index: number) => JSX.Element;
  renderLeftDrawerContent?: () => JSX.Element;
  renderRightDrawerContent?: () => JSX.Element;
  toggleButton?: JSX.Element;
}

function FeatureWithInteractiveList({
  premium = false,
  title,
  listItems,
  selectedItem,
  featuredStatName,
  FeaturedComponent,
  featureBackground,
  setSelectedItem,
  y1Measuring,
  renderListItem,
  renderLeftDrawerContent,
  renderRightDrawerContent,
  toggleButton, 
}: FeatureWithInteractiveListProps) {
  const { state, toggleDrawer, closeAllDrawers, handleClose } = useFullscreenCurtains();

  return (
    <>
      <div className={featureBackground ? 'general-container-dark' : 'general-container'}>
        <Header title={title} />
        {toggleButton}

        <div className="general-data">
          {FeaturedComponent && (
            <FeaturedComponent listItem={selectedItem} featureBackground={featureBackground} featuredStatName={featuredStatName} y1Measuring={y1Measuring} />
          )}
        </div>

        <div className="general-data-tri">
          <Rollup
            label={selectedItem?.stats[0]?.label ?? ''}
            value={selectedItem?.stats[0]?.value ?? 0}
            isPercentage={!!selectedItem?.stats[0]?.isPercentage}
          />
          <Rollup
            label={selectedItem?.stats[1]?.label ?? ''}
            value={selectedItem?.stats[1]?.value ?? 0}
            isPercentage={!!selectedItem?.stats[1]?.isPercentage}
          />
          <Rollup
            label={selectedItem?.stats[2]?.label ?? ''}
            value={selectedItem?.stats[2]?.value ?? 0}
            isPercentage={!!selectedItem?.stats[2]?.isPercentage}
          />
        </div>

        <div className="general-data-list">
          <Header title={`More ${title}`} />
          <div className="list-module">{listItems.map(renderListItem)}</div>
        </div>
      </div>

      {/* START: Drawers */}
      {premium && selectedItem && (
        <FullscreenCurtains
          header={title}
          state={state}
          handleClose={handleClose}
          closeAllDrawers={closeAllDrawers}
          selectedItem={selectedItem}
        >
          {renderLeftDrawerContent && renderLeftDrawerContent()}
          {renderRightDrawerContent && renderRightDrawerContent()}
        </FullscreenCurtains>
      )}
      {/* END: Drawers */}
    </>
  );
}

export default FeatureWithInteractiveList;