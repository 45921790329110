import React, { useState, useEffect, useMemo } from 'react';
import { channelArray } from '../../data/testData';
import FeatureWithInteractiveList from '../../components/FeatureWithInteractiveList';
import List from '../../components/List';
import Rollup from '../../components/Rollup';
import ChartArea from '../../components/ChartArea';
import ChartDetailedInStacked from '../../components/ChartDetailedInStacked';
import { noLogoPath } from '../../config';
import { ListProps } from '../../types/interfaces';

function ChannelsTop({ premium = false }: { premium?: boolean }) {
  const [selectedChannel, setSelectedChannel] = useState<ListProps | null>(null);
  const listLimit = 5;

  const createListItem = (channel: ListProps, onClick?: () => void): ListProps => ({
    ...channel,
    onClick: onClick || (() => {}),
  });

  const capitalizeWords = (str: string) =>
    str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

  const listItems: ListProps[] = useMemo(
    () =>
      channelArray.slice(0, listLimit).map((channel) => {
        const updatedChannel = {
          ...channel,
          title: capitalizeWords(channel.title),
          thirdTitle: capitalizeWords(channel.title),
        };

        return createListItem(updatedChannel, () => {
          setSelectedChannel(updatedChannel);
        });
      }),
    [],
  );

  useEffect(() => {
    if (listItems.length > 0) {
      setSelectedChannel(listItems[0]);
    }
  }, [listItems]);

  const renderListItem = (item: ListProps, index: number) => (
    <List
      key={index}
      {...item}
      title={item.secondTitle ? capitalizeWords(item.secondTitle) : ''}
      tooltipValue={`${item.title} Channel`}
      logo={item.logo ? item.logo : noLogoPath}
      stats={item.stats.slice(0, 2)}
      spark={item.spark ? item.spark : []}
      selected={!!(selectedChannel && item.title === selectedChannel.title)}
    />
  );

  return (
    <FeatureWithInteractiveList
      premium={premium}
      title="Top Channels"
      listItems={listItems}
      selectedItem={selectedChannel}
      setSelectedItem={setSelectedChannel}
      FeaturedComponent={ChartDetailedInStacked}
      featuredStatName="Total Views"
      featureBackground={false}
      renderListItem={renderListItem}
      renderLeftDrawerContent={() => (
        <>
          <ChartArea
            y1Measuring={'views'}
            height={200}
            data={channelArray[0].coords}
            y1Colour={'#50B8E7'}
            marginTop={20}
            headerContent={''}
            isMicroTooltip={true}
          />

          <div>
            <Rollup label="Mentions" value={99} />
            <Rollup label="Views" value={3000000} />
            <Rollup label="Channels" value={3} />
          </div>
        </>
      )}
      renderRightDrawerContent={() => (
        <div className="list">{channelArray.map((channel, index) => renderListItem(createListItem(channel), index))}</div>
      )}
    />
  );
}

export default ChannelsTop;
