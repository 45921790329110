import React, { useState, useEffect, useRef, useMemo } from 'react';
import { newCoinsArray } from '../../data/testData';
import FeatureWithInteractiveList from '../../components/FeatureWithInteractiveList';
import List from '../../components/List';
import ListFullscreen from '../../components/ListFullscreen';
import ChartAreaFull from '../../components/ChartAreaFull';
import ChartDetailInSimple from '../../components/ChartDetailInSimple';
import { noLogoPath } from '../../config';
import { ListProps } from '../../types/interfaces';

function CoinsNew({ premium = true }: { premium?: boolean }) {
  const [selectedCoin, setSelectedCoin] = useState<ListProps | null>(null);
  const y1Measuring = 'mentions';
  const listMax = 5;
  const [newWidth, setNewWidth] = useState(900);
  const divRef = useRef<HTMLDivElement>(null);

  const createListItem = (coin: any, onClick?: () => void): ListProps => ({
    label: coin.label,
    title: coin.coin,
    secondTitle: coin.symbol,
    thirdTitle: coin.chain,
    categories: coin.categories,
    price: coin.price,
    percentChange: coin.percentactivityChange,
    logo: coin.logo,
    stats: coin.stats,
    spark: coin.spark,
    coords: coin.coords,
    onClick: onClick || (() => {}),
  });

  const listItems: ListProps[] = useMemo(
    () =>
      newCoinsArray.map((coin) => {
        const listItem = createListItem(coin);
        return {
          ...listItem,
          onClick: () => {
            setSelectedCoin(listItem);
          },
        };
      }),
    [],
  );

  useEffect(() => {
    if (!selectedCoin && newCoinsArray.length > 0) {
      setSelectedCoin(createListItem(newCoinsArray[0]));
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const newWidth = entry.contentRect.width;
        setNewWidth(newWidth);
      }
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [selectedCoin, newCoinsArray, divRef.current]);

  const renderListItem = (item: ListProps, index: number) => (
    <List
      key={index}
      {...item}
      logo={item.logo ? item.logo : noLogoPath}
      stats={item.stats.slice(0, 2)}
      spark={item.coords ? item.coords.filter((_, i) => i % 2 === 1).map((coord) => coord.y) : []}
      selected={!!(selectedCoin && item.title === selectedCoin.title)}
    />
  );

  return (
    <FeatureWithInteractiveList
      premium={premium}
      title="New Coins"
      listItems={listItems.slice(0, listMax)}
      selectedItem={selectedCoin}
      setSelectedItem={setSelectedCoin}
      featuredStatName="Current Coin Price"
      featureBackground={false}
      FeaturedComponent={ChartDetailInSimple}
      renderListItem={renderListItem}
      y1Measuring={y1Measuring}
      renderLeftDrawerContent={() => (
        <>
          <ChartAreaFull
            y1Measuring={y1Measuring}
            height={600}
            data={selectedCoin?.coords || []}
            y1Colour={'#50B8E7'}
            marginTop={20}
            headerContent={''}
            isMicroTooltip={true}
            diagonalX={true}
          />
          {/* <div>
            <Rollup label="Mentions" value={99} />
            <Rollup label="Views" value={3000000} />
            <Rollup label="Channels" value={3} />
          </div> */}
        </>
      )}
      renderRightDrawerContent={() => (
        <div>
          {listItems.map((item, index) => (
            <ListFullscreen
              key={index}
              {...item}
              logo={item.logo ? item.logo : noLogoPath}
              stats={item.stats.slice(0, 2)}
              spark={item.coords ? item.coords.filter((_, i) => i % 2 === 1).map((coord) => coord.y) : []}
              selected={!!(selectedCoin && item.title === selectedCoin.title)}
            />
          ))}
        </div>
      )}
    />
  );
}

export default CoinsNew;
