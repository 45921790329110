import React from 'react';
import Buzzwords from '../../modules/Buzzwords';
import CoinDay from '../../modules/CoinDay';
import CoinsTop from '../../modules/CoinsTop';
import CoinsTopNew from '../../modules/CoinsTopNew';
import CoinsTopDefault from '../../modules/CoinsTopDefault';
import ChainsTop from '../../modules/ChainsTop';
import ChannelsTop from '../../modules/ChannelsTop';
import CoinsNew from '../../modules/CoinsNew';
import Sentiment from '../../modules/Sentiment';
import Chatter from '../../modules/Chatter';
import SponsorAd from '../../modules/SponsorAd';
import Promotion from '../../modules/Promotion';
import PromotionReset from '../../modules/PromotionReset';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Nav from '../../components/Nav';
import ViewsTrends from '../../modules/ViewsTrends';
import CategoriesTop from '../../modules/CategoriesTop';
import { useMyContext } from '../../ContextProvider';

const promotionStatus = localStorage.getItem('promotionStatus');

const Core: React.FC = () => {
  const { moduleBuzzwords, compactStatus } = useMyContext();

  return (
    <>
      <Nav value="" />

      <div className="masonry">
        <ResponsiveMasonry columnsCountBreakPoints={{ 360: 1, 720: 1, 960: 2, 1024: 2, 1280: 3, 1440: 3, 1600: 3, 1800: 4 }}>
          <Masonry columnsCount={2}>
            <Chatter />
            <Sentiment />
            <CategoriesTop premium={true} />
            <CoinDay />
            <CoinsTopNew/>
            <ViewsTrends />
            <CoinsNew />
            <CoinsTop />
            <CoinsTopDefault />
            {!promotionStatus ? <Promotion /> : <PromotionReset />}
            <ChainsTop />
            <ChannelsTop />
            <SponsorAd />
            <Buzzwords />
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </>
  );
};

export default Core;
