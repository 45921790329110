import React, { useState, useEffect, useMemo } from 'react';
import { chainsArray } from '../../data/testData';
import FeatureWithInteractiveList from '../../components/FeatureWithInteractiveList';
import List from '../../components/List';
import Rollup from '../../components/Rollup';
import ChartArea from '../../components/ChartArea';
import ChartDetailedInBanner from '../../components/ChartDetailedInBanner';
import { noLogoPath } from '../../config';
import { ListProps } from '../../types/interfaces';

function ChainsTop({ premium = false }: { premium?: boolean }) {
  const [selectedChain, setSelectedChain] = useState<ListProps | null>(null);
  const listLimit = 5;

  const createListItem = (chain: ListProps, onClick?: () => void): ListProps => ({
    ...chain,
    onClick: onClick || (() => {}),
  });

  const listItems: ListProps[] = useMemo(
    () =>
      chainsArray.slice(0, listLimit).map((chain) =>
        createListItem(chain, () => {
          setSelectedChain(chain);
        }),
      ),
    [],
  );

  useEffect(() => {
    if (listItems.length > 0) {
      setSelectedChain(listItems[0]);
    }
  }, [listItems]);

  const renderListItem = (item: ListProps, index: number) => (
    <List
      key={index}
      {...item}
      thirdTitle={item.thirdTitle ? `${item.thirdTitle} Chain` : ''}
      logo={item.logo ? item.logo : noLogoPath}
      stats={item.stats.slice(0, 2)}
      spark={item.spark ? item.spark : []}
      selected={!!(selectedChain && item.title === selectedChain.title)}
    />
  );

  return (
    <FeatureWithInteractiveList
      premium={premium}
      title="Top Chains"
      listItems={listItems}
      selectedItem={selectedChain}
      setSelectedItem={setSelectedChain}
      FeaturedComponent={ChartDetailedInBanner}
      featuredStatName="Total Views"
      featureBackground={false}
      renderListItem={renderListItem}
      renderLeftDrawerContent={() => (
        <>
          <ChartArea
            y1Measuring={'mentions'}
            height={200}
            data={chainsArray[0].coords}
            y1Colour={'#50B8E7'}
            marginTop={20}
            headerContent={''}
            isMicroTooltip={true}
          />

          <div>
            <Rollup label="Mentions" value={99} />
            <Rollup label="Views" value={3000000} />
            <Rollup label="Channels" value={3} />
          </div>
        </>
      )}
      renderRightDrawerContent={() => (
        <div className="list">{chainsArray.map((chain, index) => renderListItem(createListItem(chain), index))}</div>
      )}
    />
  );
}

export default ChainsTop;
